import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BubbleModal from "../components/BubbleModal";
import Button from "../components/Button";
import { ApplicationContext } from "../providers/ApplicationProvider";
import Summary from "./Summary";
import UserInfo from "./UserInfo";
import Confetti from 'react-confetti';
import { AuthContext } from "../providers/AuthProvider";
import { FinalReportContext, FinalReportProvider } from "../providers/FinalReportProvider";

export default function FinalReportSection(){
    
    const { section, reportApplication, completed, lingo } = useContext(FinalReportContext)

    let params = useParams();
    const navigate = useNavigate()
    const nodeRef = useRef(null);

    if(reportApplication === null){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }

    if(!section){
        return (
            <div className="d-flex align-items-center justify-content-center h-100" style={{minHeight: '100vh'}}>
                <FontAwesomeIcon size="3x" spin icon={['fal', 'spinner-third']} />
            </div>
        )
    }
  
    if(section.type === 'form'){

        return (
            <CSSTransition nodeRef={nodeRef} timeout={500} classNames="fade">
                <div ref={nodeRef} className="form-container">
                    <div className="sidebar">
                        <div className="close-sidebar">
                            <Button 
                                title={lingo.to_your_page}
                                leftIcon="chevron-left"
                                classes="py-2 backbutton"
                                type="secondary"
                                onClick={() => navigate('/din-side')}
                            />
                        </div>
                        <div className="sidebar-navigation">
                            {
                                reportApplication.sections.map((item, key) => {
                                    if(item.type === 'form' || item.type === 'user_info'){
                                        return (
                                            <SidebarSection
                                                key={key} 
                                                item={item}
                                            />
                                        )
                                    }
                                })
                            }
                            
                        </div>
                        
                    </div>
                    <div className="main-content">
                        <div>
                            <ProgressBar />
                            <Outlet />
                            <Tips />
                            <Footer />
                            {
                                completed ? 
                                    <BubbleModal active={completed}>
                                        <div className="text-center" style={{
                                                maxWidth: '60%', 
                                                margin: '0 auto'
                                            }}>
                                                <h5>{lingo.final_report_sent_title}</h5>
                                                <FontAwesomeIcon className="py-4 text-green" size="3x" icon={['far', 'check']} />
                                                <div className="mb-3">
                                                    <h2>{lingo.final_report_thank_you}</h2>
                                                    <p>{lingo.final_report_thank_you_text}</p>
                                                </div>
                                                <div className="row justify-content-center mt-5">
                                                    <div className="col-auto">
                                                        <Button
                                                        title={lingo.application_submitted_back_to_webpage}
                                                            type="secondary"
                                                            onClick={() => {
                                                                navigate('/webpage');
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        <Button
                                                            title={lingo.application_portal}
                                                            type="primary"
                                                            onClick={() => {
                                                                navigate('/din-side');
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    </BubbleModal>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>
        )
    }

    return(
        <div>
            <h3>Section</h3>
        </div>
    )
}

function SidebarSection({ item }){
    const { section, reportApplication, configJsonArray, formData, application, lingo } = useContext(FinalReportContext)
    const { title, icon, short_description, url } = item
    const navigate = useNavigate();

    const params = useParams();

    const [navError, setNavError] = useState([]);
    const [validSection, setValidSection] = useState(false);

    let thisSection = configJsonArray.filter(el => el.url === url)
    let thisIndex = configJsonArray.findIndex(el => el.url === section.url)

    useEffect(() => {
        try{
            let sectionFields;
            if(thisSection.length && typeof(thisSection) !== undefined){

                if(thisSection[0].items.length){

                    sectionFields = thisSection[0].items.map(n => n.name);

                    if(sectionFields.length){
                        let validCheck = [];
                        sectionFields.forEach((input) => {
                            if(formData.hasOwnProperty(input) && typeof formData[input] !== "undefined" && formData[input].hasOwnProperty('valid')){
                                validCheck.push(formData[input].valid);
                            }
                        })
                        if(validCheck.includes(false)){
                            setValidSection(false)
                        }else{
                            setValidSection(true);
                        }
                    }else{
                        setValidSection(false)
                    }
                }
            }
            return () => sectionFields = [];
        }catch(e){

        }
        
    }, [formData])

    const handleClick = () => {
        const sectionIndex = reportApplication.sections.findIndex(s => s.url === url)

        const clickedIndex = configJsonArray.findIndex(s => s.url === url);
        const diff = clickedIndex - thisIndex;

        
        let canNavigate = false;

        //Kan alltid navigere seg bakover
        if(diff > 0){
            let errorSections = [];
            for(let i = thisIndex; clickedIndex > i; i++){
                if(!configJsonArray[i].valid){
                    errorSections.push(configJsonArray[i].title);
                    continue;
                }
            }
            if(errorSections.length){
                setNavError(errorSections);
            }
            else{
                canNavigate = true;
            }
        }else{
            canNavigate = true;
        }

        if(canNavigate){
             navigate(`/sluttrapport/${application.hash}/${reportApplication.sections[sectionIndex].url}/${reportApplication.sections[sectionIndex].forms[0].url}`);

        }else{

            if(navError.length >= 2){
                alert(lingo.final_report_no_move + ' ' + navError.join(', ') + '. ' + lingo.final_report_no_move_2);
            }else{
                alert(lingo.final_report_no_move_3);
            }
            
        }
    }
    return (
        
        <div className={"sidebar-navigation-item " + (params.section===url ? 'active ' : '') + (thisSection[0]?.valid && validSection ? 'valid ': '')}>
            <div className="clickarea" onClick={() => handleClick()}>
                <div className="sidebar-navigation-item-content">
                    <h6 className="pointer mt-3 mt-lg-0">{title}</h6>
                    <p className="d-none d-lg-block">{short_description}</p>
                </div>
                <div className="sidebar-navigation-item-icon pointer">
                {(thisSection[0]?.valid && validSection ? <FontAwesomeIcon icon={['fal', 'check']} />: <FontAwesomeIcon icon={['fal', icon]} />)}
                </div>
            </div>
        </div>
        
    )
}

function ProgressBar(){
    const { reportApplication, formData, updatePercent, lingo } = useContext(FinalReportContext)
    const [totalProgress, setTotalProgress] = useState(0)
    const [currentProgress, setCurrentProgress] = useState(0)  

    useEffect(() => {
        updateProgress()
    }, [formData])

    const updateProgress = () => {
        let total = 0;
        let current = 0;

        let countFields = [];
        let validFields = [];

        reportApplication.sections.forEach((section) => {
            
            if(section.forms && section.forms.length){
                section.forms.forEach((form) => {
                    if(form.elements){
                        form.elements.forEach((element) => {
                            const shouldCount = element.required;
                            
                            if(shouldCount){
                                if(element.type !== 'calculate'){
                                    total += 1;
                                    countFields.push(element);
                                }

                                
                                                            
                                if(formData[element.name] && formData[element.name].valid){
                                    current += 1
                                    validFields.push(formData[element.name]);
                                }
                            }
                        })
                    }
                })
            }
            
        })
        setCurrentProgress(current)
        setTotalProgress(total)
        updatePercent(Math.round((current / total) * 100))
    }
    
    return (
        <div className="progress-container mb-4">
            <div className="row justify-content-between align-items-center pb-1">
                <div className="col-auto">
                    <h6 className="mb-0">{reportApplication.title}</h6>
                </div>
                
                <div className="col text-end">
                    <small>{Math.round((currentProgress / totalProgress) * 100)}% {lingo.done}</small>
                </div>
            </div>
            <div className="progress">
                <div className="progress-indicator" style={{width: (currentProgress / totalProgress) * 100 +'%'}}></div>
            </div>
        </div>
    )
}

function Footer(){
    const { section, form, formData, reportApplication, percent, setAsCompleted, next, prev, lingo } = useContext(FinalReportContext)

    const [canGoBack, setCanGoBack] = useState(false);
    const [canFinish, setCanFinish] = useState(false);
    const [validScreen, setValidScreen] = useState(false);
    
    const location = useLocation()

    const updateValidScreen = () => {

        let currFormFields = [];

        //Finner feltene på nåværende skjerm og pusher de inn i currFormFields
        if(form){
            if(form.elements.length){
                form.elements.forEach((field) => {
                    //if(field.name){
                    if (field.name && field?.rules?.required && !field?.readonly) {
                        currFormFields.push(field.name);
                    }
                })
            }
        }
        if(currFormFields.length){
            let validCheck = [];

            currFormFields.forEach((input) => {
                if(formData[input] && formData[input].hasOwnProperty('valid')){
                    validCheck.push(formData[input].valid)
                }
            })
            if(validCheck.includes(false) && validCheck.length){
                setValidScreen(false)
            }else{
                //setValidScreen(true);
                if (currFormFields.length <= validCheck.length) {
                    setValidScreen(true);
                } else {
                    setValidScreen(false);
                }
            }
        }else{
            setValidScreen(false)
        }
    }

    useEffect(() => {
        updateValidScreen();
    }, [formData, next, prev]);

    useEffect(() => {
        
        if(section){
            let bool = false
            const sectionIndex = reportApplication.sections.findIndex(s => s.url === section.url)
            if(reportApplication.sections[sectionIndex - 1]){
                setCanGoBack(true)
                bool = true
            }

            

            if(form){
                const formIndex = section.forms.findIndex(f => f.url === form.url)

                if(!reportApplication.sections[sectionIndex + 1] && !section.forms[formIndex + 1]){
                    setCanFinish(true)
                }else{
                    setCanFinish(false)
                }
                if(section.forms[formIndex - 1]){
                    setCanGoBack(true)
                    bool = true
                    return
                }
            }
            if(!bool) setCanGoBack(false)
            
        }

    }, [location, section, form]);

    

    return (
        <div className="row justify-content-between bottombuttonwrapper">
            <div className="col-auto">
                { canGoBack ? 
                <Button 
                    title={lingo.previous}
                    type="secondary"
                    leftIcon="arrow-left"
                    onClick={() => prev()}
                />
                : null}
            </div>
            <div className="col-auto">

                { canFinish ? 
                    <Button 
                        title={lingo.finnished}
                        type="primary"
                        rightIcon="check"
                        onClick={() => setAsCompleted()}
                        disabled={percent !== 100}
                        
                    />
                : (validScreen ?
                    <Button title={lingo.next} type="primary" rightIcon="arrow-right" onClick={() => next()} />
                    : 
                        <Button title={lingo.next} type="disabled" rightIcon="arrow-right" onClick={async () => { alert( lingo.fill_inn_all_fields ) }}/>
                )}
            </div>
        </div>
    )
}

export function Tips(){
    const { application, setApplicationData, section, animate, form, next } = useContext(ApplicationContext)
    let params = useParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null);

    if(form && form.tips){

        const {icon, title, value, link} = form.tips;

        return(
            <CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade">
                <div className="form-content" ref={nodeRef}>
                    <div className="row">
                        {icon ? 
                            <div className="col-auto">
                                <img src={process.env.PUBLIC_URL + '/svg/' + icon} className='mb-4' />    
                            </div>
                        : null}
                        <div className="col">
                            {title ? <h5 className="text-blue">{title}</h5> : null}
                            {value ? <div dangerouslySetInnerHTML={{__html: value}}></div> : null}
                            {link && link.title && link.url ? <p className="pt-3"><a href={link.url} target="_blank">{link.title}</a></p>: ''}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    return(<CSSTransition nodeRef={nodeRef} in={animate} timeout={500} classNames="fade"><div ref={nodeRef}></div></CSSTransition>);
}